
$(function () {
    "use strict";

    $('.carousel-showmanymoveone').slick({
        slidesToShow: 4,
        infinite: true,
        autoplay: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 1441,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });


    $('.slider-fotoboek').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        infinite: true,
        autoplay: true

    });


    if ($("#meer-voorstellingen").length > 0) {
        var source = document.getElementById("agenda-template").innerHTML;
        var template = Handlebars.compile(source);

        $("#meer-voorstellingen").click(function (e) {
            e.preventDefault();

            var $link = $(this);

            $.get($link.attr("href"), {
                skip: $link.data("skip"),
                voorstelling: $link.data("voorstelling"),
                schoolvoorstellingen: $link.data("schoolvoorstellingen")
            }, function (data) {
                console.log(data);
                var html = template(data);

                $link
                    .before($(html))
                    .data("skip", data.skip);

                if (!data.meerItems) {
                    $link.hide();
                }
            });
        });
    }

    if ($("#locatie-filter").length > 0) {
        var ticketsSource = document.getElementById("tickets-template").innerHTML;
        var ticketsTemplate = Handlebars.compile(ticketsSource);

        $("#locatie-filter").change(function () {
            var $select = $(this);

            if ($select.val() === "-") {
                return;
            }

            $.get($select.data("url"), { voorstelling: $select.data("voorstelling"), locatie: $select.val() }, function (data) {
                var html = ticketsTemplate(data);

                $("#agenda-results")
                    .empty()
                    .html(html);
            });
        });
    }

    $('#homepage_slider').slick({
        autoplay: true,
        nextArrow: '<i class="slick-next"></i>',
        prevArrow: '<i class="slick-prev"></i>'
    });

    $('.quote_slider_home').slick({
        arrows: false,
        autoplay: true
    });


    $('.slider-wrapper').on({
        mouseenter: function () {
            isPause = true;
        },
        mouseleave: function () {
            isPause = false;
        }
    });

    //function startProgressbar() {
    //	resetProgressbar();
    //	percentTime = 0;
    //	isPause = false;
    //	tick = setInterval(interval, 10);
    //}

    //function interval() {
    //	if (isPause === false) {
    //		percentTime += 1 / (time + 0.1);
    //		$bar.css({
    //			width: percentTime + "%"
    //		});
    //		if (percentTime >= 100) {
    //			$slick.slick('slickNext');
    //			startProgressbar();
    //		}
    //	}
    //}

    //function resetProgressbar() {
    //	$bar.css({
    //		width: 0 + '%'
    //	});
    //	clearTimeout(tick);
    //}

    //startProgressbar();

    $("#homepage_slider a.trailer").click(function (e) {
        e.preventDefault();

        $("#trailer-iframe").attr("src", $(this).attr("href"));
        $("#trailer-title").text($(this).data("titel"));
        $("#trailer-modal").modal("show");
    });

    $("#trailer-modal").on("hide.bs.modal", function () {
        $("#trailer-iframe").attr("src", "");
    });


    $(".mailchimpform")
        .validate({
            submitHandler: function (form) {
                var $form = $(form);

                $.post($form.attr("action"), $form.serialize(), function (data) {
                    if (data.result === "success") {
                        $form.fadeOut("fast", function () {
                            $(".mailing-result-success").fadeIn("fast");
                        });
                    }
                    else {
                        $form.fadeOut("fast", function () {
                            $(".mailing-result-fail").fadeIn("fast");
                        });
                    }
                }).fail(function () {
                    $form.fadeOut("fast", function () {
                        $(".mailing-result-fail").fadeIn("fast");
                    });
                });
            },
            errorPlacement: function (error, element) { },
            rules: {
                name: "required",
                surname: "required",
                email: {
                    required: true,
                    email: true
                },
                datacheck: "required"
            }
        });

});

function showhide(id) {
    var e = document.getElementById(id);
    e.style.display = e.style.display === 'block' ? 'none' : 'block';
}

//if (IE9) {
//    $(".row.equal-cols > [class*='col-']").matchHeight();
//}

var tracker;
function sendEvent(category, action, label) {
    if (!tracker && window.ga) {
        ga(function () {
            tracker = ga.getAll()[0];
        });
    }
    if (tracker) {
        tracker.send('event', category, action, label);
    }
}